import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Container, Row, Col, Badge, Spinner } from "reactstrap";
import Swal from "sweetalert2";
import clonedeep from "lodash.clonedeep";

import { getCardapioCompleto } from "services/cardapio";
import { removeAccents } from "infra/utils";
import { useLayoutContext } from "contexts/layout";

import ModalProduto from "../../components/ModalProduto";
import { ContainerProduto, GrupoItem } from "./styles";
import Produto from "./Produto";

import NavbarSticky from "./NavbarSticky";
import { FundoCardapio } from "../../Menu/MenuPage/components/styles";
import { checkoutCart, getAllCart, getOrigem } from "services/cart";
import { navigate } from "infra/router/history";

import styled from 'styled-components';

function ListaCardapio() {
  const { layoutData, update: updateContext } = useLayoutContext();
  const [filtros, setFiltros] = useState({});
  const [grupos, setGrupos] = useState([]);
  const [sugestao, setSugestao] = useState([]);
  const [produto, setProduto] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [gruposFiltro, setGruposFiltro] = useState([]);
  const [isOpenProduto, setIsOpenProduto] = useState(false);

  const pontoEntrega = JSON.parse(localStorage.getItem("pontoEntrega"));
  const origem = getOrigem()
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);

      const { result, grupos, sugestaoDia } = await getCardapioCompleto(3);
      setGrupos(result);
      setGruposFiltro(grupos)
      setSugestao(sugestaoDia);

      setIsLoading(false);
    }
    fetchData();

    return () => {
      setGrupos([]);
      setGruposFiltro([]);
      setSugestao([]);
      setIsLoading(false);
    };
  }, []);

  useEffect(() => {
    if (!layoutData.flgPedidoPontoEntrega && pontoEntrega !== null) {
      localStorage.removeItem("pontoEntrega")
    }
  }, [layoutData.flgPedidoPontoEntrega, pontoEntrega]);


  const handleClick = useCallback(
    (item) => {


      if (item.flgEmFalta) {
        return Swal.fire("Aviso", "Produto em falta!", "error");
      }

      setProduto(item);
      setIsOpenProduto(true);
    },
    []
  );

  const cartItemCount = getAllCart().length;

  const handleChange = useCallback(
    (prop, value) => {
      setFiltros((prev) => ({ ...prev, [prop]: value }));
    },
    [setFiltros]
  );
  useEffect(() => {
    if (origem === 'menu') {
      checkoutCart();
      updateContext();
    }
  }, [layoutData, origem, updateContext]);
  const result = useMemo(() => {
    let retorno = clonedeep([...grupos]);

    if (filtros.categoria) {
      retorno = retorno.filter((x) => x.nome === filtros.categoria);
    }

    if (filtros.search) {
      retorno.forEach((x) => {
        x.items.forEach((grupo) => {
          grupo.produtos = grupo.produtos.filter((produto) => {
            return removeAccents(produto.nomeCompleto.toLowerCase()).includes(
              removeAccents(filtros.search.toLowerCase())
            );
          });
        });

        x.items = x.items.filter((grupo) => grupo.produtos.length);
      });

      retorno = retorno.filter((x) => x.items.length);
    }

    return retorno;
  }, [grupos, filtros]);

  return (
    <div
      style={{ backgroundColor: "#fafafc", overflow: "hidden", paddingTop: 16 }}
    >
      <FundoCardapio color={layoutData?.cardapioCorFundo}>
        <Container>
          {isLoading && (
            <Spinner style={{ margin: "32px auto", display: "table" }} />
          )}

          <Row>
            <Col md={9}>
              {isOpenProduto ? (
                <ModalProduto
                  isDelivery={true}
                  open={isOpenProduto}
                  item={produto}
                  onToggle={() => setIsOpenProduto(!isOpenProduto)}
                  onSaved={() => setIsOpenProduto(false)}
                />
              ) : null}

              <ContainerProduto>
                <NavbarSticky
                  isDelivery={true}
                  grupos={gruposFiltro}
                  indice={grupos}
                  value={filtros}
                  onChange={handleChange}
                />

                <div>
                  <br />

                  <Row>
                    <Col sm={12}>
                      {sugestao.length ? (
                        <div style={{ color: layoutData.cardapioCorTextoGrupo }}>
                          <GrupoItem>
                            {sugestao.length > 1 ? (
                              <h4>Sugestões do dia </h4>
                            ) : (
                              <h4>Sugestão do dia</h4>
                            )}
                            <Row>
                              {sugestao.map((produto, key) => (
                                <Col md={6} xs={12} key={key}>
                                  <div style={{ marginBottom: 16 }}>
                                    <Produto
                                      onClick={() => handleClick(produto)}
                                      item={produto}
                                    />
                                  </div>
                                </Col>
                              ))}
                            </Row>
                          </GrupoItem>
                        </div>
                      ) : null}
                      <div>
                        {result.map((grupo, key) =>
                          grupo.items?.length ? (
                            <div key={key} id={`gru-${grupo.id}`}>
                              <GrupoItem key={key}>
                                <h3>{grupo.nome}</h3>
                                <div>
                                  {grupo.items.map((categoria, k) =>
                                    categoria.produtos.length ? (
                                      <div key={k} id={`cat-${categoria.id}`}>
                                        <Row>
                                          <Col xs={12}>
                                            <h4 style={{ float: "left", color: layoutData.cardapioCorTextoGrupo }}>
                                              {categoria.nome}
                                            </h4>{" "}
                                            <Badge>
                                              {categoria.produtos.length} produtos
                                            </Badge>
                                          </Col>
                                        </Row>
                                        <Row>
                                          {categoria.produtos.map((produto, key) => (
                                            <Col md={6} xs={12} key={key}>
                                              <div style={{ marginBottom: 16 }}>
                                                <Produto
                                                  onClick={() =>
                                                    handleClick(produto)
                                                  }
                                                  item={produto}
                                                />
                                              </div>
                                            </Col>
                                          ))}
                                        </Row>
                                      </div>
                                    ) : null
                                  )}
                                </div>
                              </GrupoItem>
                            </div>
                          ) : null
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </ContainerProduto>
            </Col>
          </Row>

          <FooterNav cartItemCount={cartItemCount} primaryColor={layoutData.primaryColor} />
        </Container>
      </FundoCardapio>
    </div>
  );
}

export default ListaCardapio;





// Estilos para o container do footer
const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  border-top: 1px solid #e0e0e0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  justify-content: space-around;
  padding: 8px 0;
  align-items: center;
`;

// Estilos para o botão de navegação
const NavButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ color }) => color};
  cursor: pointer;
  justify-content: flex-end;  
`;

// Estilos para o badge de contagem do carrinho
const CartBadge = styled.span`
  position: absolute;
  top: -8px;
  right: -10px;
  background-color: ${({ color }) => color}; /* Azul padrão */
  color: white;
  padding: 0px 8px ;
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
`;

export const FooterNav = ({ cartItemCount = 0, primaryColor = "#e2392f" }) => {

  const currentPage = window.location.pathname.split('/')[2]
  const isCardapio = currentPage === 'delivery'
  const isCarrinho = currentPage === 'carrinho'
  return (
    <FooterContainer>
      {/* Botão Início */}
      <NavButton onClick={() => navigate("delivery")} color={isCardapio ? primaryColor : ''}>
        <i className="fa fa-home" style={{ fontSize: '1.5em' }} />
        <span style={{ fontSize: '12px' }}>Início</span>
      </NavButton>

      {/* Botão Carrinho com Badge */}
      <NavButton onClick={() => navigate("carrinho-delivery")} color={isCarrinho ? primaryColor : ''}>
        <div style={{ position: 'relative' }}>
          <i className="fa fa-shopping-cart" style={{ fontSize: '1.5em' }} />
          {cartItemCount > 0 && (
            <CartBadge color={primaryColor}>{cartItemCount}</CartBadge>
          )}
        </div>
        <span style={{ fontSize: '12px' }}>Carrinho</span>
      </NavButton>
    </FooterContainer>
  );
};

