import React, { useState, useEffect, useCallback, useRef } from "react";
import { formatMoney } from "infra/utils";
import RangeQuantidade from "./RangeQuantidade";
import { Row, Col } from "reactstrap";

const RenderVariacaoItem = ({ item, tamanhoVariacaoProdutoId, readonly, onChange }) => {
  const [maximoDisponivelParaVariacao, setMaximoDisponivelParaVariacao] = useState(item.qtdeMax);
  const itemRefs = useRef([]); // Referência para os containers das variações

  const calculeQuantidadeMaxima = useCallback((variacao) => {
    let quantidadeJaUsada = 0;

    variacao.items.forEach((item) => {
      quantidadeJaUsada += item.quantidade || 0;
    });

    return variacao.qtdeMax - quantidadeJaUsada;
  }, []);

  const updateMaximoDisponivelParaVariacao = useCallback(() => {
    setMaximoDisponivelParaVariacao(calculeQuantidadeMaxima(item));
  }, [item, calculeQuantidadeMaxima]);

  useEffect(() => {
    updateMaximoDisponivelParaVariacao();
  }, [item, updateMaximoDisponivelParaVariacao]);

  const scrollToNext = (index) => {
    const nextSibling = itemRefs.current[index + 1];

    if (nextSibling) {
      // Rolando suavemente até o próximo item e garantindo que ele fique no topo da visualização
      nextSibling.scrollIntoView({
        behavior: "smooth",
        block: "start", // Faz com que o item fique no topo da visualização
      });

    }
  };

  const onChangeQuantidadeMultiplaESoma = (value, currentItem, index) => {
    const variacao = { ...item };

    let quantidadeJaSelecionada = 0;

    variacao.items.forEach((itemFor) => {
      if (itemFor.id === currentItem.id) {
        itemFor.quantidade = value;
      }
      quantidadeJaSelecionada += itemFor.quantidade || 0;
    });

    variacao.quantidadeJaSelecionada = quantidadeJaSelecionada;

    onChange(variacao);
    updateMaximoDisponivelParaVariacao();

    if (quantidadeJaSelecionada >= variacao.qtdeMax) {
      scrollToNext(index); // Rola para a próxima variação
    }
  };

  const onChangeQuantidadeRadio = (currentItem, index) => {
    if (readonly) return;

    const variacao = { ...item };
    const isAdicao = !currentItem.quantidade;

    let quantidadeJaSelecionada = 0;

    variacao.items.forEach((itemFor) => {
      itemFor.quantidade = 0;

      if (itemFor.id === currentItem.id) {
        itemFor.quantidade = isAdicao ? 1 : 0;
      }

      quantidadeJaSelecionada += itemFor.quantidade;
    });

    variacao.quantidadeJaSelecionada = quantidadeJaSelecionada;
    onChange(variacao);

    if (quantidadeJaSelecionada >= variacao.qtdeMax) {
      scrollToNext(index); // Rola para a próxima variação
    }
  };

  const renderMultiple = (currentItem, index) => {
    if (readonly) return null;

    const max = (currentItem.quantidade || 0) + maximoDisponivelParaVariacao;

    return (
      <RangeQuantidade
        min={0}
        max={max}
        value={currentItem.quantidade || 0}
        onChange={(value) => onChangeQuantidadeMultiplaESoma(value, currentItem, index)}
      />
    );
  };

  const filteredItems = (item.items || []).filter(
    (x) => x.tamanhoVariacaoProdutoId === tamanhoVariacaoProdutoId
  );

  if (filteredItems.length === 0) {
    return <div />;
  }

  return (
    <Col sm={6}>
      <div
        style={{
          backgroundColor: "#f7f7f7",
          marginTop: 20,
          paddingLeft: 16,
          paddingRight: 16,
          paddingTop: 16,
          borderRadius: 4,
        }}
      >
        <div
          style={{
            flexDirection: "column",
            marginBottom: 6,
            marginTop: 6,
            paddingLeft: 0,
            paddingRight: 16,
            display: "flex",
          }}
        >
          <div>
            <div style={{ fontSize: 18, fontWeight: "bold" }}>
              {item.nomeMostra}
            </div>
          </div>
          <div>
            {item.qtdeMax > 1 && item.flgTipo !== "S" ? (
              <div style={{ fontSize: 12, color: "#999" }}>
                Escolha as {item.qtdeMax} partes deste produto.
              </div>
            ) : null}
            {item.flgTipo === "S" ? (
              <div style={{ fontSize: 12, color: "#999" }}>
                Escolha até {item.qtdeMax} {item.qtdeMax > 1 ? "opções" : "opção"}.
              </div>
            ) : null}
          </div>
        </div>

        {filteredItems.map((itemFor, key) => (
          <div
            style={{
              marginLeft: 0,
              marginRight: 0,
              paddingRight: 0,
              borderBottom: "1px solid #ddd",
              paddingTop: 8,
              paddingBottom: 8,
              cursor: item.flgTipo === "U" && !readonly ? "pointer" : null,
            }}
            onClick={() =>
              item.flgTipo === "U" ? onChangeQuantidadeRadio(itemFor, key) : undefined
            }
            key={key}
          >
            <div ref={(el) => (itemRefs.current[key] = el)}>
              <Row>
                <Col xs={8}>
                  <div style={{ fontSize: 14 }}>{itemFor.nomeCompleto}</div>

                  {!!itemFor.textoInformativo ? (
                    <Row style={{ marginTop: 10 }}>
                      <Col>
                        <div
                          style={{
                            fontSize: 12,
                            color: "#999",
                          }}
                        >
                          {itemFor.textoInformativo}
                        </div>
                      </Col>
                    </Row>
                  ) : null}

                  <div>
                    <div
                      style={{
                        fontWeight: item.selected && "bold",
                        marginTop: 5,
                        fontSize: 14,
                      }}
                    >
                      R$ {formatMoney(itemFor.preco)}
                    </div>
                  </div>
                </Col>

                <Col xs={4}>
                  <div>
                    <div style={{ textAlign: "right" }}>
                      {item.flgTipo === "U" ? (
                        itemFor.quantidade === 1 ? (
                          <span className="fa fa-check-circle" />
                        ) : null
                      ) : (
                          renderMultiple(itemFor, key)
                        )}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        ))}
      </div>
    </Col>
  );
};

export default RenderVariacaoItem;