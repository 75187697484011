import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { useContext } from "react";
import { LayoutContext } from "contexts/layout";
import UserAddress from "../UserAddress";
import { getAllCart, getOrigem } from "services/cart";
import AuthClient from "infra/auth/AuthClient";
import history from "infra/router/history";
import ProdutoTotais from "./ProdutoTotais";
import ListaProdutos from "./ListaProdutos";
import useCookies from "infra/hooks/useCookies";

const ContainerCard = styled.div`
  background: white;
  padding: 16px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: 1;
  width: 330px;
  overflow-y: auto;

  h5 {
    font-size: 18px;
  }

  @media screen and (max-width: 768px) {
    & {
      display: none;
    }
  }
`;

const ButtonContained = styled.button`
  background: ${(props) => (props.disabled ? "#ccc" : props.color)};
  padding: 12px 8px;
  border: none;
  outline: none;
  color: white;
  display: block;
  width: 100%;
  border-radius: 6px;
  font-size: 13px;

  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: 0;
  }
`;

function CarrinhoCard() {
  const [, updateState] = useState();

  const { layoutData } = useContext(LayoutContext);

  const forceUpdate = useCallback(() => updateState({}), []);
  const { getCookie } = useCookies();

  const estaNaTelaFinalizacao = history.location.pathname.includes("/carrinho");
  const estaNaTelaAcompanhamento =
    history.location.pathname.includes("/acompanhamento");
  const authData = AuthClient.getData();
  const isUserLogged = AuthClient.isUserLogged();

  const isPedidoOff = !isUserLogged && !!getCookie("idPedidoOff") && !!getCookie("chavePedidoOff") && layoutData?.wgPedidoDeliveryOFF;

  const items = getAllCart();
  const origem = getOrigem();

  if (estaNaTelaAcompanhamento) {
    return null;
  }

  if (!authData && !isPedidoOff) {
    return <ContainerCard style={{ textAlign: "center" }} />;
  }


  return (
    <ContainerCard>
      {!estaNaTelaFinalizacao && origem === "delivery" ? (
        <div>
          <UserAddress forceUpdate={forceUpdate} />
        </div>
      ) : null}

      {items.length ? (
        <div>
          <h5 style={{ marginTop: 24 }}>Resumo do pedido</h5>

          <ListaProdutos items={items} />

          <ProdutoTotais />

          {!estaNaTelaFinalizacao ? (
            <div>
              <ButtonContained
                onClick={() => {
                  history.push(`/${layoutData.tenant}/carrinho`);
                }}
                disabled={!items.length}
                color={layoutData.primaryColor}
              >
                Fechar o pedido
              </ButtonContained>
            </div>
          ) : null}
        </div>
      ) : (
        <div style={{ textAlign: "center", marginTop: 32 }}>
          <i
            className="fa fa-shopping-basket fa-4x"
            style={{ color: "#eee" }}
          />
          <p style={{ color: "#666" }}>Seu carrinho esta vazio!</p>
        </div>
      )}
    </ContainerCard>
  );
}

export default CarrinhoCard;
